import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import { BlogCta } from "./helpdesk-problems-solutions";
import "react-accessible-accordion/dist/fancy-example.css";

import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";
import { CaseStudyCtaGoTo } from "./generative-ai-itsm";

const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");
const header_image = require("../../../assets/img/blogs/kaes_blog_header.png");
const header_image_mobile = require("../../../assets/img/blogs/kaes_blog_header_mob.png");
const section_1 = require("../../../assets/img/blogs/aiitsm_blog_image_1.png");
const section_2 = require("../../../assets/img/blogs/aiitsm_blog_image_2.png");
const section_3 = require("../../../assets/img/blogs/aiitsm_blog_image_3.png");
const section_4 = require("../../../assets/img/blogs/aiitsm_blog_image_4.png");
const cta_1 = require("../../../assets/img/blogs/tsvg_cta_1.png");
const cta_2 = require("../../../assets/img/blogs/tsvg_cta_2.png");
const cta_1_mob = require("../../../assets/img/blogs/tsvg_cta_1_mob.png");
const cta_2_mob = require("../../../assets/img/blogs/tsvg_cta_2_mob.png");
const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });
  const backgroundImage = isSmall ? header_image_mobile : header_image;
  return (
    <>
      <TitleAndMetaTags
        title=" Watermelon Effects on ITSM: Are you Measuring your SLAs Right?"
        description="The watermelon effects of SLAs on your ITSM hinder your employee productivity. Get GenAI-based chatbots to make employee support effective and prioritize experienced-based support."
        keywords={[
          "ai for itsm, ai itsm, itsm, itsm ai, ai in itsm, ai service management, artificial intelligence service management",
        ]}
        ogImage={header_image}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section
            className={`w-100 float-left blog_top_header_upt striking_blog_header_upt ${
              isSmall ? "align-items-center" : null
            }`}
            style={{
              backgroundImage: `url(${backgroundImage})`,
            }}
          >
            <div className="container">
              <div
                className={`flex_cont_top_blog_img text-align-center ${
                  isMobile ? "" : ""
                }`}
              >
                {" "}
                <h1 className="font-page-header-home-blog color-white">
                  The Watermelon Effect Of SLAs On ITSM
                </h1>
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}
          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. What is the watermelon effect of SLAs on ITSM?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. What are some examples of real-world watermelon effects on
                  ITSM?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. How to prevent watermelon effects?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section4"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section4"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section4")}
                >
                  4. What is the real benefit of experience-based metrics?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section5"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section5"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section5")}
                >
                  5. The experience advantage: How Workativ provides support to
                  prevent watermelon effects
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section6"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section6"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section6")}
                >
                  6. FAQs
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                ITSM, or{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/itsm-chatbot-guide">
                  IT service management,
                </a>{" "}
                describes the best ways to fulfill employee support requirements
                through tools and knowledge management.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                As an essential part of ITSM, SLAs lay the groundwork for
                analyzing KPIs or metrics for service desk managers.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                But do service-level agreement metrics reflect the true story of
                the behind-the-scene circumstances?
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is common for service desks to offer a fix to an employee
                support issue and help them get back to work. From that
                perspective, IT service delivery meets the service level
                agreement (SLA).
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, only meeting SLAs does not translate into high customer
                experience and satisfaction.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If you observe, your employees are not getting much from the
                traditional service desks. The wait time is drastically longer.
                A correct response would take time, and an issue may be
                repetitive.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It can be similar to what you see isn’t what you get—it is
                similar to a watermelon. In ITSM, the successful service
                delivery as per SLA, yet the decline in customer experience and
                satisfaction is known as the{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  watermelon effect.
                </span>{" "}
                It’s green outside but red inside.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                But why is it called the watermelon effect in ITSM? How can you
                reduce it? Let’s explore these questions.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What is the watermelon effect of SLAs on ITSM?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Service Level Agreements (SLAs) are rules outlining what
                services an ITSM must provide to its users and within what
                timeframe.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s say your users expect to address password resets
                frequently. If you use{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  GenAI-based chatbots,
                </a>{" "}
                you must define how effectively they should address a user’s
                problem through an SLA.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                So, SLAs define the target for each service and provide the best
                practices to fulfill its goals.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Unfortunately, while SLAs aim to set clear performance targets,
                they often fail to capture the true service experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                There comes how watermelon effects grapple your service desks.
                Often handed with wrong data, service desks only get data on the
                service provided and not on how it works on user expectation.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  watermelon effect highlights this gap: SLAs might appear green
                  (meeting targets) on the surface, but the underlying reality
                  is often red (poor service, dissatisfied customers).{" "}
                </span>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This scenario occurs when employees hide the truth about service
                quality and continue to receive poor service, resulting in
                misleading metrics.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                To enhance employee experience and drive business value,
                organizations must look beyond SLAs and focus on delivering
                exceptional service that exceeds expectations.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What are some examples of real-world watermelon effects on ITSM?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                In many cases, you can notice that SLAs are not always rightly
                perceived. ITSM performs as per target, but services are
                compromised. Here are possible reasons.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                1. Subpar performance of company devices
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                A study reveals that employees get their tools and devices at
                work. However, they are unhappy because they are unreliable and
                can fail at any point, causing significant productivity losses.
                SLAs can only reveal the exterior part of the business. Despite
                the end-user issues, organizations can only see the green part,
                although the inside is red.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                2. Poorest employee experience in self-service IT support{" "}
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Though an{" "}
                <a href="https://workativ.com/conversational-ai-platform/self-service-automation">
                  IT self-service portal
                </a>{" "}
                is widely used, it does not have many tools and features to
                support real-time problem resolutions. Instead of self-service,
                employees use traditional methods like emails, phone calls, and
                SMS to get their queries answered. Traditional KPIs only reveal
                the data of many users but not how effectively they serve
                people. Again, you only get green on the outside, but it still
                has red inside—the watermelon effect in your ITSM.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                3. Negative CSAT experience{" "}
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                The familiar scene in a traditional ITSM is that your employees
                have to repeat the same story repeatedly. Yet, they still don’t
                know when their tickets will be resolved.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Your service desk tool might contain negative data for CSAT.
                However, it is tough to pinpoint the data that leads to the
                actual cause of employee dissatisfaction.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                4. Neglecting employee feedback{" "}
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                There is no feedback, no real-time cognizance of the real
                issues. This is the leading cause of why most organizations fail
                to spot the blind with employee dissatisfaction. There are no
                regular feedback cadences for employees on their IT support
                experience. When you have a similar work process, it is hard to
                trace what causes employee dissatisfaction and leaves no room
                for improvement in ITSM response, collaboration, and problem
                resolution.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The watermelon effects are nowhere to go from your service desk
                unless you have a modern ITSM suite of tools. Here is how you
                can prevent watermelon effects from your service desks.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How to prevent watermelon effects?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                The watermelon effects on ITSM are simply a wrong assumption of
                SLA’s achievement. As SLAs go wrong, the metrics appear green
                outside and red inside. Levering AITM or AISM helps you get the
                right SLA metrics, avoid the nuances of watermelon effects, and
                drive real employee experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Here are the ways how you can prevent watermelon effects.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Generative AI-based ITSM
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                When your ITSM leverages modern tech innovations like generative{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/digital-transformation-generative-ai">
                  AI or LLM-based point-of-contact for service desks,
                </a>{" "}
                communications become much easier and goal-driven.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                You facilitate extended automation capabilities for routine
                service desk issues. Generative AI's inherent content generation
                and NLP data processing allow your people to have contextual and
                intent-based answers. It reduces the need to create workflows
                for new and unique cases. Instead, Generative AI can understand
                the query and provide answers but refrain from surfacing
                rudimentary answers. It helps speed up the response and
                resolution of routine problems.
              </p>

              <h3 className="font-section-sub-header-small color-black">
                LLM-based self-service
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Integrating your service desk channels with Generative AI or LLM
                properties translates your self-service chatbot into a{" "}
                <a href="https://www.google.com/search?q=chatgpt+like+interface-++workativ&newwindow=1&sca_esv=9775d443102feade&rlz=1C5MACD_enIN1045IN1046&sxsrf=ADLYWIKkGKwUMcNZlKwirsyqVIM2N6QVzQ%3A1722940602397&ei=uvyxZt3gF5rH4-EPm-ixiQw&ved=0ahUKEwjdpcz0leCHAxWa4zgGHRt0LMEQ4dUDCBA&uact=5&oq=chatgpt+like+interface-++workativ&gs_lp=Egxnd3Mtd2l6LXNlcnAiIWNoYXRncHQgbGlrZSBpbnRlcmZhY2UtICB3b3JrYXRpdjIIECEYoAEYwwRI6CBQAFjwHnAAeACQAQCYAe0BoAH_GaoBBjAuMjEuMbgBA8gBAPgBAZgCC6AC-wzCAggQABgHGAgYHsICCBAAGIAEGKIEwgIIEAAYogQYiQWYAwCSBwQwLjExoAeePQ&sclient=gws-wiz-serp#:~:text=Boost%20Collaboration%20%26%20Engagement,ai%2Dplatform%20%E2%80%BA%20blog">
                  ChatGPT-like interface.
                </a>{" "}
                Backed by domain-specific knowledge articles, your employees can
                find enterprise-specific answers like ChatGPT, which delivers to
                every user quickly.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                There are article links for your reference to confirm the
                veracity of a response. Yet, the flexibility to harness
                real-time consolidated responses helps find answers quickly and
                resolve issues in real time.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Moreover, an instant agent handover is enabled if a user does
                not receive an answer to her query. So, an employee does not
                have to wait days for a simple answer and lose productivity.
                LLM-based self-service portals aim at employee satisfaction, not
                only compliance with SLA targets.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Accurate data metrics
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Unlike the traditional ITSM approach, which makes it tough to
                collect accurate data metrics, the LLM-based service desk helps
                fetch real-time accurate data. There are no misleading data
                points that only highlight SLA targets' achievement. Rather,
                Generative AI-based advanced analytics represent data points
                that show which tickets were unresolved, what the repetitive
                issues were, what the wait time was, what the duration of
                service delivery was, etc. These data points give enough
                visibility into service desk performance and provide ample
                recommendations to improve service quality.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Personalized and contextual responses
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                To combat watermelon effects in ITSM, you can automate employee
                service in advance. LLM-based ITSM enables workflows to notify
                employees of a software update or password reset. Besides,
                LLM-based chat takes care of empathy to connect with a user
                personally and builds a deeper connection with each employee. As
                a result, employees love to use self-service, resolve more
                issues independently, and be complacent with IT support.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Service delivery improvement with feedback
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                LLM-based service desk eliminates the need for manual feedback
                collection. After an interaction, the genAI-based chatbot asks
                for experience with service delivery. The automated workflows
                capture interaction data with the system, effectively prepare
                questions on case history and user persona, and help collect
                feedback. Answers collected from feedback help stakeholders look
                into loopholes periodically and implement workflows that align
                with user objectives.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                An LLM-based service desk fulfills what a traditional approach
                prevents for effective SLA metrics. This combats the challenges
                of watermelon effects and helps improve customer experience with
                the correct and accurate KPI metrics.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What is the real benefit of experience-based metrics?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                The fact behind why the watermelon effect persists is the poor
                detection of KPI metrics in service desks.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Generally, service desks capture metrics about the success of
                service delivery as per SLA targets. In doing so, they neglect
                customer experience and expedite the chances of the watermelon
                effect.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                With an LLM-based service desk, you can prioritize customer
                experience over IT performance and harness experience-level
                agreement (XLA) by eliminating service-level agreement (SLA).
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Focusing on experience metrics for service desk users can unlock
                accurate outcome-based metrics and improve customer experience.
                This may allow you to build happy users and boost employee
                retention.
              </p>

              <BlogCta
                ContentCta="Optimize Your IT Service Management with AI."
                ButtonText="Book a Demo"
                isColor="white"
                backgroundImage={cta_2}
                mobileBackgroundImage={cta_2_mob}
              />
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                The experience advantage: How Workativ provides support to
                prevent watermelon effects
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18">
                One of the significant ways to address the watermelon effect is
                to improve visibility across user interactions and service
                delivery. Workativ provides support for end-to-end visibility
                into how your users interact with the service desks and how they
                leverage tools from a GenAI-based chatbot to combat their
                problems. Workativ has comprehensive GenAI chatbot solutions for
                employee support to eliminate the watermelon effects and
                prioritize customer experience.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Here are what employees can use to derive maximum satisfaction
                from a service desk.
              </p>
              <h3 className="font-section-sub-header-small">
                1. Knowledge AI or LLM-powered chatbot
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                The speed of accuracy for every question searched satisfies
                employees, who look to find fast answers and do their best work.
                Knowledge AI or RAG-based search systems allow employees to find
                information from third-party sources or knowledge repositories
                while improving correctness and eliminating hallucinations to
                amplify self-service capabilities. Employees can find correct
                answers, auto-resolve problems, and give agents free time to
                focus on strategic tasks.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With Knowledge AI, employees can reduce wait time, align with
                SLA objectives, and remove the watermelon effects.
              </p>
              <h3 className="font-section-sub-header-small">
                2. Workflow automation
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ’s conversational AI harnesses ChatGPT-like qualities to
                translate every query into GenAI-based chats and transform the
                employee experience. All you need to do is connect your
                knowledge bases to Knowledge AI, and you can automate workflows
                for a query your employees have. This can also help get answers
                quickly, reduce wait time, and boost employee experience.
              </p>
              <h3 className="font-section-sub-header-small">
                3. Shared live inbox
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ’s shared live inbox keeps every agent updated with
                tickets escalated by employees. Agents know the priority of
                employee queries and address them as quickly as possible with
                full visibility across employee ticket history. Without the need
                to tell the same story repeatedly, an LLM-based chat inbox
                unifies each piece of information at the same place, giving a
                view of ongoing tickets and providing help to resolve them with
                help from teammates.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ plays a key role in removing the watermelon effects
                from your service desks by facilitating employee support by all
                means. Employees can find answers quickly, resolve problems
                steadily, and reduce wait times, which is otherwise a key reason
                to trigger employee unhappiness.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                As Workativ improves the employee experience, SLAs also improve
                and provide the right metrics on user experience only rather
                than IT performance.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                When you implement Workativ, you can remove the watermelon
                effect and boost experience-based metrics, which ultimately help
                build employee experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If you want to learn more about implementing GenAI-based
                chatbots and remove the watermelon effects, Workativ can help.{" "}
                <a href="https://calendly.com/workativ/workativ-assistant-demo-page">
                  Schedule a demo today.{" "}
                </a>
              </p>

              <CaseStudyCtaGoTo colorKey="peach" textColor="blue" />
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                FAQs
              </h2>
              <h3 className="font-section-sub-header-small">
                1. What is the watermelon effect in ITSM?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                When your ITSM performance metrics are green, like the skin of a
                watermelon, despite poor employee support performance, but they
                are red inside, this scenario is known as the watermelon effect
                in ITSM.
              </p>
              <h3 className="font-section-sub-header-small">
                2. Why does the watermelon effect take place?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                The watermelon effect in ITSM occurs due to poor identification
                of ITSM metrics. Service desk metrics appear positive based on
                the service provided but not on how it can meet customer
                queries. Hence, the service desk continues to work on the
                processes without any improvement, which adds to customer
                dissatisfaction.
              </p>

              <h3 className="font-section-sub-header-small">
                3. Why is the watermelon effect harmful to your company’s
                growth?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                The watermelon effect reduces productivity and increases the
                costs of handling employee queries. On the other hand, employees
                do not get the most of their service desks to perform at their
                best.
              </p>

              <h3 className="font-section-sub-header-small">
                4. How can Generative AI help eliminate the watermelon effects?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI leverages massive datasets to process information
                and provide instant answers for auto-resolutions of problems. As
                a result, it helps improve self-service, increases response
                time, provides data analytics to improve service delivery, and
                eliminates the watermelon effects.
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}

function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. What is the watermelon effect of SLAs on ITSM?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. What are some examples of real-world watermelon effects
                    on ITSM?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. How to prevent watermelon effects?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section4"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section4"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section4")}
                  >
                    4. What is the real benefit of experience-based metrics?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section5"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section5"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section5")}
                  >
                    5. The experience advantage: How Workativ provides support
                    to prevent watermelon effects
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section6"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section6"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section6")}
                  >
                    6. FAQs
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                ITSM, or{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/itsm-chatbot-guide">
                  IT service management,
                </a>{" "}
                describes the best ways to fulfill employee support requirements
                through tools and knowledge management.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                As an essential part of ITSM, SLAs lay the groundwork for
                analyzing KPIs or metrics for service desk managers.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                But do service-level agreement metrics reflect the true story of
                the behind-the-scene circumstances?
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is common for service desks to offer a fix to an employee
                support issue and help them get back to work. From that
                perspective, IT service delivery meets the service level
                agreement (SLA).
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, only meeting SLAs does not translate into high customer
                experience and satisfaction.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If you observe, your employees are not getting much from the
                traditional service desks. The wait time is drastically longer.
                A correct response would take time, and an issue may be
                repetitive.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It can be similar to what you see isn’t what you get—it is
                similar to a watermelon. In ITSM, the successful service
                delivery as per SLA, yet the decline in customer experience and
                satisfaction is known as the{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  watermelon effect.
                </span>{" "}
                It’s green outside but red inside.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                But why is it called the watermelon effect in ITSM? How can you
                reduce it? Let’s explore these questions.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What is the watermelon effect of SLAs on ITSM?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Service Level Agreements (SLAs) are rules outlining what
                services an ITSM must provide to its users and within what
                timeframe.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s say your users expect to address password resets
                frequently. If you use{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  GenAI-based chatbots,
                </a>{" "}
                you must define how effectively they should address a user’s
                problem through an SLA.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                So, SLAs define the target for each service and provide the best
                practices to fulfill its goals.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Unfortunately, while SLAs aim to set clear performance targets,
                they often fail to capture the true service experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                There comes how watermelon effects grapple your service desks.
                Often handed with wrong data, service desks only get data on the
                service provided and not on how it works on user expectation.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  watermelon effect highlights this gap: SLAs might appear green
                  (meeting targets) on the surface, but the underlying reality
                  is often red (poor service, dissatisfied customers).{" "}
                </span>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This scenario occurs when employees hide the truth about service
                quality and continue to receive poor service, resulting in
                misleading metrics.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                To enhance employee experience and drive business value,
                organizations must look beyond SLAs and focus on delivering
                exceptional service that exceeds expectations.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What are some examples of real-world watermelon effects on ITSM?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                In many cases, you can notice that SLAs are not always rightly
                perceived. ITSM performs as per target, but services are
                compromised. Here are possible reasons.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                1. Subpar performance of company devices
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                A study reveals that employees get their tools and devices at
                work. However, they are unhappy because they are unreliable and
                can fail at any point, causing significant productivity losses.
                SLAs can only reveal the exterior part of the business. Despite
                the end-user issues, organizations can only see the green part,
                although the inside is red.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                2. Poorest employee experience in self-service IT support{" "}
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Though an{" "}
                <a href="https://workativ.com/conversational-ai-platform/self-service-automation">
                  IT self-service portal
                </a>{" "}
                is widely used, it does not have many tools and features to
                support real-time problem resolutions. Instead of self-service,
                employees use traditional methods like emails, phone calls, and
                SMS to get their queries answered. Traditional KPIs only reveal
                the data of many users but not how effectively they serve
                people. Again, you only get green on the outside, but it still
                has red inside—the watermelon effect in your ITSM.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                3. Negative CSAT experience{" "}
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                The familiar scene in a traditional ITSM is that your employees
                have to repeat the same story repeatedly. Yet, they still don’t
                know when their tickets will be resolved.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Your service desk tool might contain negative data for CSAT.
                However, it is tough to pinpoint the data that leads to the
                actual cause of employee dissatisfaction.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                4. Neglecting employee feedback{" "}
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                There is no feedback, no real-time cognizance of the real
                issues. This is the leading cause of why most organizations fail
                to spot the blind with employee dissatisfaction. There are no
                regular feedback cadences for employees on their IT support
                experience. When you have a similar work process, it is hard to
                trace what causes employee dissatisfaction and leaves no room
                for improvement in ITSM response, collaboration, and problem
                resolution.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The watermelon effects are nowhere to go from your service desk
                unless you have a modern ITSM suite of tools. Here is how you
                can prevent watermelon effects from your service desks.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How to prevent watermelon effects?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                The watermelon effects on ITSM are simply a wrong assumption of
                SLA’s achievement. As SLAs go wrong, the metrics appear green
                outside and red inside. Levering AITM or AISM helps you get the
                right SLA metrics, avoid the nuances of watermelon effects, and
                drive real employee experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Here are the ways how you can prevent watermelon effects.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Generative AI-based ITSM
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                When your ITSM leverages modern tech innovations like generative{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/digital-transformation-generative-ai">
                  AI or LLM-based point-of-contact for service desks,
                </a>{" "}
                communications become much easier and goal-driven.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                You facilitate extended automation capabilities for routine
                service desk issues. Generative AI's inherent content generation
                and NLP data processing allow your people to have contextual and
                intent-based answers. It reduces the need to create workflows
                for new and unique cases. Instead, Generative AI can understand
                the query and provide answers but refrain from surfacing
                rudimentary answers. It helps speed up the response and
                resolution of routine problems.
              </p>

              <h3 className="font-section-sub-header-small color-black">
                LLM-based self-service
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Integrating your service desk channels with Generative AI or LLM
                properties translates your self-service chatbot into a{" "}
                <a href="https://www.google.com/search?q=chatgpt+like+interface-++workativ&newwindow=1&sca_esv=9775d443102feade&rlz=1C5MACD_enIN1045IN1046&sxsrf=ADLYWIKkGKwUMcNZlKwirsyqVIM2N6QVzQ%3A1722940602397&ei=uvyxZt3gF5rH4-EPm-ixiQw&ved=0ahUKEwjdpcz0leCHAxWa4zgGHRt0LMEQ4dUDCBA&uact=5&oq=chatgpt+like+interface-++workativ&gs_lp=Egxnd3Mtd2l6LXNlcnAiIWNoYXRncHQgbGlrZSBpbnRlcmZhY2UtICB3b3JrYXRpdjIIECEYoAEYwwRI6CBQAFjwHnAAeACQAQCYAe0BoAH_GaoBBjAuMjEuMbgBA8gBAPgBAZgCC6AC-wzCAggQABgHGAgYHsICCBAAGIAEGKIEwgIIEAAYogQYiQWYAwCSBwQwLjExoAeePQ&sclient=gws-wiz-serp#:~:text=Boost%20Collaboration%20%26%20Engagement,ai%2Dplatform%20%E2%80%BA%20blog">
                  ChatGPT-like interface.
                </a>{" "}
                Backed by domain-specific knowledge articles, your employees can
                find enterprise-specific answers like ChatGPT, which delivers to
                every user quickly.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                There are article links for your reference to confirm the
                veracity of a response. Yet, the flexibility to harness
                real-time consolidated responses helps find answers quickly and
                resolve issues in real time.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Moreover, an instant agent handover is enabled if a user does
                not receive an answer to her query. So, an employee does not
                have to wait days for a simple answer and lose productivity.
                LLM-based self-service portals aim at employee satisfaction, not
                only compliance with SLA targets.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Accurate data metrics
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Unlike the traditional ITSM approach, which makes it tough to
                collect accurate data metrics, the LLM-based service desk helps
                fetch real-time accurate data. There are no misleading data
                points that only highlight SLA targets' achievement. Rather,
                Generative AI-based advanced analytics represent data points
                that show which tickets were unresolved, what the repetitive
                issues were, what the wait time was, what the duration of
                service delivery was, etc. These data points give enough
                visibility into service desk performance and provide ample
                recommendations to improve service quality.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Personalized and contextual responses
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                To combat watermelon effects in ITSM, you can automate employee
                service in advance. LLM-based ITSM enables workflows to notify
                employees of a software update or password reset. Besides,
                LLM-based chat takes care of empathy to connect with a user
                personally and builds a deeper connection with each employee. As
                a result, employees love to use self-service, resolve more
                issues independently, and be complacent with IT support.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Service delivery improvement with feedback
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                LLM-based service desk eliminates the need for manual feedback
                collection. After an interaction, the genAI-based chatbot asks
                for experience with service delivery. The automated workflows
                capture interaction data with the system, effectively prepare
                questions on case history and user persona, and help collect
                feedback. Answers collected from feedback help stakeholders look
                into loopholes periodically and implement workflows that align
                with user objectives.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                An LLM-based service desk fulfills what a traditional approach
                prevents for effective SLA metrics. This combats the challenges
                of watermelon effects and helps improve customer experience with
                the correct and accurate KPI metrics.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What is the real benefit of experience-based metrics?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                The fact behind why the watermelon effect persists is the poor
                detection of KPI metrics in service desks.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Generally, service desks capture metrics about the success of
                service delivery as per SLA targets. In doing so, they neglect
                customer experience and expedite the chances of the watermelon
                effect.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                With an LLM-based service desk, you can prioritize customer
                experience over IT performance and harness experience-level
                agreement (XLA) by eliminating service-level agreement (SLA).
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Focusing on experience metrics for service desk users can unlock
                accurate outcome-based metrics and improve customer experience.
                This may allow you to build happy users and boost employee
                retention.
              </p>

              <BlogCta
                ContentCta="Optimize Your IT Service Management with AI."
                ButtonText="Book a Demo"
                isColor="white"
                backgroundImage={cta_2}
                mobileBackgroundImage={cta_2_mob}
              />
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                The experience advantage: How Workativ provides support to
                prevent watermelon effects
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18">
                One of the significant ways to address the watermelon effect is
                to improve visibility across user interactions and service
                delivery. Workativ provides support for end-to-end visibility
                into how your users interact with the service desks and how they
                leverage tools from a GenAI-based chatbot to combat their
                problems. Workativ has comprehensive GenAI chatbot solutions for
                employee support to eliminate the watermelon effects and
                prioritize customer experience.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Here are what employees can use to derive maximum satisfaction
                from a service desk.
              </p>
              <h3 className="font-section-sub-header-small">
                1. Knowledge AI or LLM-powered chatbot
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                The speed of accuracy for every question searched satisfies
                employees, who look to find fast answers and do their best work.
                Knowledge AI or RAG-based search systems allow employees to find
                information from third-party sources or knowledge repositories
                while improving correctness and eliminating hallucinations to
                amplify self-service capabilities. Employees can find correct
                answers, auto-resolve problems, and give agents free time to
                focus on strategic tasks.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With Knowledge AI, employees can reduce wait time, align with
                SLA objectives, and remove the watermelon effects.
              </p>
              <h3 className="font-section-sub-header-small">
                2. Workflow automation
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ’s conversational AI harnesses ChatGPT-like qualities to
                translate every query into GenAI-based chats and transform the
                employee experience. All you need to do is connect your
                knowledge bases to Knowledge AI, and you can automate workflows
                for a query your employees have. This can also help get answers
                quickly, reduce wait time, and boost employee experience.
              </p>
              <h3 className="font-section-sub-header-small">
                3. Shared live inbox
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ’s shared live inbox keeps every agent updated with
                tickets escalated by employees. Agents know the priority of
                employee queries and address them as quickly as possible with
                full visibility across employee ticket history. Without the need
                to tell the same story repeatedly, an LLM-based chat inbox
                unifies each piece of information at the same place, giving a
                view of ongoing tickets and providing help to resolve them with
                help from teammates.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ plays a key role in removing the watermelon effects
                from your service desks by facilitating employee support by all
                means. Employees can find answers quickly, resolve problems
                steadily, and reduce wait times, which is otherwise a key reason
                to trigger employee unhappiness.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                As Workativ improves the employee experience, SLAs also improve
                and provide the right metrics on user experience only rather
                than IT performance.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                When you implement Workativ, you can remove the watermelon
                effect and boost experience-based metrics, which ultimately help
                build employee experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If you want to learn more about implementing GenAI-based
                chatbots and remove the watermelon effects, Workativ can help.{" "}
                <a href="https://calendly.com/workativ/workativ-assistant-demo-page">
                  Schedule a demo today.{" "}
                </a>
              </p>

              <CaseStudyCtaGoTo colorKey="peach" textColor="blue" />
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                FAQs
              </h2>
              <h3 className="font-section-sub-header-small">
                1. What is the watermelon effect in ITSM?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                When your ITSM performance metrics are green, like the skin of a
                watermelon, despite poor employee support performance, but they
                are red inside, this scenario is known as the watermelon effect
                in ITSM.
              </p>
              <h3 className="font-section-sub-header-small">
                2. Why does the watermelon effect take place?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                The watermelon effect in ITSM occurs due to poor identification
                of ITSM metrics. Service desk metrics appear positive based on
                the service provided but not on how it can meet customer
                queries. Hence, the service desk continues to work on the
                processes without any improvement, which adds to customer
                dissatisfaction.
              </p>

              <h3 className="font-section-sub-header-small">
                3. Why is the watermelon effect harmful to your company’s
                growth?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                The watermelon effect reduces productivity and increases the
                costs of handling employee queries. On the other hand, employees
                do not get the most of their service desks to perform at their
                best.
              </p>

              <h3 className="font-section-sub-header-small">
                4. How can Generative AI help eliminate the watermelon effects?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI leverages massive datasets to process information
                and provide instant answers for auto-resolutions of problems. As
                a result, it helps improve self-service, increases response
                time, provides data analytics to improve service delivery, and
                eliminates the watermelon effects.
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
          </div>
        </div>
      </div>
      <AuthorBio />
    </section>
  );
}
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/top-itsm-trends-2023"
              className="font-section-normal-text-testimonials"
            >
              TOP ITSM Trends 2023: Preparing for the Future
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/itsm-chatbot-guide"
              className="font-section-normal-text-testimonials"
            >
              ITSM CHATBOT- Use Cases and Benefits Explained
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/service-desk-automation"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              Service Desk Automation: ELEVATE YOUR IT SUPPORT EFFICIENCY
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
